<template>
  <form action="" class="container">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb pl-0 mb-0 pb-2">
          <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('signup.title') }}</li>
        </ol>
      </nav>
      <div class="page-title signup-page-title text-lg-left text-center">
        {{ $t('signup.title') }}
      </div>
    </div>

    <img src="/images/vave-bg.svg" class="signup-form-bg">

    <div class="row mx-xs-0 mx-md-4 mx-lg-4">
      <div class="col-md-12">
        <div class="container p-0">
          <div class="px-4">
            <div class="row">
                <div class="col-md-12 signup-form">
                    <div class="checkbox-block">
                        <div class="form-check pl-0" v-on:click="isResident = true; isNotResident = false">
                            <el-radio v-model="role" label="1">{{$t("signup.resident")}}</el-radio>
                        </div>
                        <div class="form-check" v-on:click="isResident = false; isNotResident = true">
                            <el-radio v-model="role" label="2">{{$t("signup.non-resident")}}</el-radio>
                        </div>
                    </div>

                    <div class="row" v-if="!isNotResident">
                        <div class="col-lg-4 col-md-12">
                            <div class="signup-requered-top-mob pt-0 pb-4 text-center">
                            {{ $t('signup.requered-fields') }}
                            </div>
                            <div class="signup__required-fields signup__required-fields--resident">
                                {{$t("signup.required-fields")}}
                            </div>
                            <form-group :field="$v.account.gender" :label="$t('references.gender')" name="gender"
                                        class="form-group gender-mob">
                                <el-radio-group v-model="account.gender" class="w-100">
                                    <el-radio-button label="gender-1">{{ $t('references.gender-male') }}</el-radio-button>
                                    <el-radio-button label="gender-2">{{ $t('references.gender-female') }}</el-radio-button>
                                </el-radio-group>
                            </form-group>
                            <form-group :field="$v.account.iin"
                                        :label="$t('references.required-field') + $t('profile.profile.iin-label')" name="iin">
                                <el-input
                                    maxlength="12"
                                    :placeholder="$t('profile.profile.iin-placeholder')"
                                    v-model="account.iin"
                                    @input="getDataFromIin"
                                    @change="$v.account.iin.$touch()"
                                    show-word-limit>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.last_name"
                                        :label="$t('references.required-field') + $t('profile.profile.last-name-label')"
                                        name="last_name">
                                <el-input
                                    :placeholder="$t('profile.profile.last-name-placeholder')"
                                    v-model="account.last_name"
                                    @change="$v.account.last_name.$touch()"
                                    clearable>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.first_name"
                                        :label="$t('references.required-field') + $t('profile.profile.first-name-label')"
                                        name="first_name">
                                <el-input
                                    :placeholder="$t('profile.profile.first-name-placeholder')"
                                    v-model="account.first_name"
                                    @change="$v.account.first_name.$touch()"
                                    clearable>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.patronymic" :label="$t('profile.profile.patronymic-label')"
                                        name="patronymic">
                                <el-input
                                    :placeholder="$t('profile.profile.patronymic-placeholder')"
                                    v-model="account.patronymic"
                                    clearable>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.birth_date"
                                        :label="$t('references.required-field') + $t('signup.birth-date-label')"
                                        name="birth_date">
                                <el-date-picker
                                    :picker-options="pickerOptions"
                                    style="width: 100%;"
                                    v-model="account.birth_date"
                                    type="date"
                                    popper-class = "signup-date-picker"
                                    placeholder="Дата"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    @change="$v.account.birth_date.$touch()"
                                >
                                </el-date-picker>
                            </form-group>
                            <form-group :field="$v.account.gender"
                                        :label="$t('references.gender')" name="gender"
                                        class="mob-hidden">
                            <el-select
                                :placeholder="$t('references.gender')"
                                v-model="account.gender">
                                <el-option
                                    v-for="item in genders"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>
                        </div>
                        <div class="col-lg-4 col-md-12">

                            <form-group :field="$v.account.region_id"
                                        :label="$t('references.required-field') + $t('profile.profile.region-label')"
                                        name="region_id">
                            <el-select
                                :placeholder="$t('profile.profile.region-placeholder')"
                                v-model="account.region_id"
                                :loading="regionsLoading"
                                @change="getCities"
                                filterable>
                                <el-option
                                    v-for="item in regions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>
                            <form-group :field="$v.account.city_id"
                                        :label="$t('references.required-field') + $t('profile.profile.district-label')"
                                        name="city_id">
                            <el-select
                                :placeholder="$t('profile.profile.district-placeholder')"
                                v-model="account.city_id"
                                :loading="citiesLoading"
                                @change="getLocalities"
                                filterable>
                                <el-option
                                    v-for="item in cities"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>
                            <form-group :field="$v.account.locality_id"
                                        :label="$t('references.required-field') + $t('profile.profile.city-label')"
                                        name="locality_id"
                                        >
                            <el-select
                                autocomplete="nope"
                                v-model="account.locality_id"
                                :placeholder="$t('profile.profile.city-placeholder')"
                                :loading="schoolsLoading"
                                @change="getSchools"

                                filterable>
                                <el-option
                                    v-for="item in localities"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>


                            <form-group :field="$v.account.school_id" :label="$t('references.required-field') + $t('profile.profile.organization-name-label')">
                            <el-select
                                v-model="account.school_id"
                                :placeholder="$t('profile.profile.organization-name-placeholder')"
                                filterable>
                                <el-option
                                    :no-match-text="$t('references.schools-not-found')"
                                    :no-data-text="$t('references.schools-not-found')"
                                    v-for="item in schools"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>

                            <form-group :field="$v.account.class_id"
                                        :label="$t('references.required-field') + $t('profile.profile.class-label')"
                                        name="class_id">
                            <el-select
                                autocomplete="nope"
                                v-model="account.class_id"
                                :placeholder="$t('profile.profile.class-placeholder')"
                                >
                                <el-option
                                    :key="5"
                                    :label="5+$t('profile.profile.class')"
                                    :value="5">
                                </el-option>
                                <el-option
                                    :key="6"
                                    :label="6+$t('profile.profile.class')"
                                    :value="6">
                                </el-option>
                            </el-select>
                            </form-group>

                            <form-group :field="$v.account.language_id"
                                        :label="$t('references.required-field') + $t('profile.profile.education-language-label')"
                                        name="language_id">
                            <el-select
                                autocomplete="nope"
                                v-model="account.language_id"
                                :placeholder="$t('profile.profile.education-language-label')"
                                >
                                <el-option
                                    :key="1"
                                    :label="$t('profile.profile.language-kz')"
                                    :value="1">
                                </el-option>
                                <el-option
                                    :key="2"
                                    :label="$t('profile.profile.language-ru')"
                                    :value="2">
                                </el-option>
                                <el-option
                                    :key="3"
                                    :label="$t('profile.profile.language-en')"
                                    :value="3">
                                </el-option>
                            </el-select>
                            </form-group>


                            <form-group :field="$v.account.change_of_training_id"
                                        :label="$t('references.required-field') + $t('profile.profile.other-nonresident-label')"
                                        name="change_of_training_id">
                            <el-select
                                autocomplete="nope"
                                v-model="account.change_of_training_id"
                                :placeholder="$t('profile.profile.change_of_training-placeholder')">
                                <el-option
                                    :key="1"
                                    :label="1+' '+$t('profile.profile.change_of_training')"
                                    :value="1">
                                </el-option>
                                <el-option
                                    :key="2"
                                    :label="2+' '+$t('profile.profile.change_of_training')"
                                    :value="2">
                                </el-option>
                                <el-option
                                    :key="3"
                                    :label="3+' '+$t('profile.profile.change_of_training')"
                                    :value="3">
                                </el-option>
                            </el-select>
                            </form-group>

                        </div>
                        <div class="col-lg-4 col-md-12">
                             <div class="col-12 px-0 signup__logo">
                                <img src="/images/Birge-Oqy-profile.svg" width="124px" height="104px">
                            </div>

                            <form-group :field="$v.account.email" :label="$t('references.required-field') + $t('profile.profile.email-label')" name="email">
                            <!--                      <label :class="{'text-danger': !$v.account.email.email}" class="signin-label" for="email">{{ $t('references.email-label') }}*</label>-->
                            <el-input
                                id="email"
                                :placeholder="$t('profile.profile.email-placeholder')"
                                v-model="account.email"
                                @change="$v.account.email.$touch()"
                                clearable>
                            </el-input>
                            </form-group>


                            <form-group :field="$v.account.password"
                                        :label="$t('references.required-field') + $t('profile.profile.password-label')"
                                        name="password">
                            <el-input
                                :placeholder="$t('profile.profile.password-placeholder')"
                                v-model="password"
                                @input="onPasswordInput"
                                @change="$v.password.$touch()"
                                show-password>
                            </el-input>
                            </form-group>
                            <form-group :field="$v.account.confirm_password"
                                        :label="$t('references.required-field') + $t('profile.profile.password-label-submit')"
                                        name="password">
                            <el-input
                                :placeholder="$t('profile.profile.password-placeholder-submit')"
                                v-model="confirm_password"
                                @input="onPasswordConfirmInput"
                                @change="$v.confirm_password.$touch()"
                                show-password></el-input>
                            </form-group>
                            <form-group :field="$v.account.phone"
                                        :label="$t('references.required-field') + $t('profile.profile.phone-label')"
                                        name="phone">
                            <el-input
                                :disabled="phoneVerified"
                                :placeholder="$t('profile.profile.phone-placeholder')"
                                v-model="phone"
                                v-mask="'+7 (###) ### ##-##'"
                                @input="enforcePhoneFormat"
                                clearable>
                            </el-input>
                            </form-group>
                            <div class="signup-additional-info pb-2">
                            {{ $t('signup.registration-is-possible') }}
                            </div>
                            <div class="phone-is-confirmed pb-2 d-none">
                                <img width="15" src="/images/tick-green.svg" class="mr-1">
                                {{ $t('signup.phone-is-confirmed') }}
                            </div>
                            <div class="confirm-phone-btn-block">
                                <button class="confirm-phone-btn" @click="openModal" v-b-modal.sms-modal type="button">
                                    {{ $t('signup.confirm-phone') }}
                                </button>
                            </div>
                            <div class="signup-accept-block">
                                <div class="form-check pl-0 pt-4">
                                    <label class="form-check-label" for="all">
                                        {{ $t("signup.accept") }} 
                                        <span class="form-check-label-accept">{{ $t("signup.accept-2") }}</span>
                                        <input class="form-check-input" type="checkbox" name="filter" id="all" value="0"
                                                v-model="acceptData">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pt-2 px-0">
                            <div class="signup-submit-block">
                                <div class="signup__fill-fields">
                                    {{ $t('signup.fill-fields') }}
                                </div>
                                <el-button @click="createUser" class="btn-finish ml-0" :disabled="!acceptData">
                                    {{ $t('signup.signup-btn') }}
                                </el-button>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="!isResident">
                        <div class="col-lg-4 col-md-12">
                            <form-group :field="$v.account.document_id"
                                        :label="$t('signup.non-resident')"
                                        name="document_id">
                                <el-select
                                    autocomplete="nope"
                                    v-model="account.document_id"
                                    :placeholder="$t('signup.document-type')"
                                    >
                                    <el-option
                                        :key="1"
                                        :label="$t('signup.document-type-1')"
                                        :value="1">
                                    </el-option>
                                    <el-option
                                        :key="2"
                                        :label="$t('signup.document-type-2')"
                                        :value="2">
                                    </el-option>
                                    <el-option
                                        :key="3"
                                        :label="$t('signup.document-type-3')"
                                        :value="3">
                                    </el-option>
                                </el-select>
                            </form-group>
                        </div>
                        <div class="col-lg-9 col-md-9">
                            <div class="signup__attach-block">
                                <div class="signup__image-upload">
                                    <label for="file-input" class="signup__attach-btn">
                                        <img src="/images/attach-icon.svg" width="16" height="16"/>
                                        {{ $t('signup.attach-file') }}
                                    </label>

                                    <input id="file-input" type="file" />
                                </div>
                                <div class="signup__attached-file">
                                    {{ $t('signup.attached-file') }}
                                </div>
                            </div>
                            <div class="signup__required-fields signup__required-fields--non-resident">
                                {{$t("signup.required-fields")}}
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 px-0">
                            <div class="signup__logo--non">
                                <img src="/images/Birge-Oqy-profile.svg" width="146" height="123">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="signup-requered-top-mob pt-0 pb-4 text-center">
                            {{ $t('signup.requered-fields') }}
                            </div>
                            <form-group :field="$v.account.gender" :label="$t('references.gender')" name="gender"
                                        class="form-group gender-mob">
                                <el-radio-group v-model="account.gender" class="w-100">
                                    <el-radio-button label="gender-1">{{ $t('references.gender-male') }}</el-radio-button>
                                    <el-radio-button label="gender-2">{{ $t('references.gender-female') }}</el-radio-button>
                                </el-radio-group>
                            </form-group>
                            <form-group :field="$v.account.iin"
                                        :label="$t('references.required-field') + $t('profile.profile.iin-label')" name="iin">
                                <el-input
                                    maxlength="12"
                                    :placeholder="$t('profile.profile.iin-placeholder')"
                                    v-model="account.iin"
                                    @input="getDataFromIin"
                                    @change="$v.account.iin.$touch()"
                                    show-word-limit>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.last_name"
                                        :label="$t('references.required-field') + $t('profile.profile.last-name-label')"
                                        name="last_name">
                                <el-input
                                    :placeholder="$t('profile.profile.last-name-placeholder')"
                                    v-model="account.last_name"
                                    @change="$v.account.last_name.$touch()"
                                    clearable>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.first_name"
                                        :label="$t('references.required-field') + $t('profile.profile.first-name-label')"
                                        name="first_name">
                                <el-input
                                    :placeholder="$t('profile.profile.first-name-placeholder')"
                                    v-model="account.first_name"
                                    @change="$v.account.first_name.$touch()"
                                    clearable>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.patronymic" :label="$t('profile.profile.patronymic-label')"
                                        name="patronymic">
                                <el-input
                                    :placeholder="$t('profile.profile.patronymic-placeholder')"
                                    v-model="account.patronymic"
                                    clearable>
                                </el-input>
                            </form-group>
                            <form-group :field="$v.account.birth_date"
                                        :label="$t('references.required-field') + $t('signup.birth-date-label')"
                                        name="birth_date">
                                <el-date-picker
                                    :picker-options="pickerOptions"
                                    style="width: 100%;"
                                    v-model="account.birth_date"
                                    type="date"
                                    popper-class = "signup-date-picker"
                                    placeholder="Дата"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    @change="$v.account.birth_date.$touch()"
                                >
                                </el-date-picker>
                            </form-group>
                            <form-group :field="$v.account.gender"
                                        :label="$t('references.gender')" name="gender"
                                        class="mob-hidden">
                            <el-select
                                :placeholder="$t('references.gender')"
                                v-model="account.gender">
                                <el-option
                                    v-for="item in genders"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>
                        </div>
                        <div class="col-lg-4 col-md-12">

                            <form-group :field="$v.account.region_id"
                                        :label="$t('references.required-field') + $t('profile.profile.region-label')"
                                        name="region_id">
                            <el-select
                                :placeholder="$t('profile.profile.region-placeholder')"
                                v-model="account.region_id"
                                :loading="regionsLoading"
                                @change="getCities"
                                filterable>
                                <el-option
                                    v-for="item in regions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>
                            <form-group :field="$v.account.city_id"
                                        :label="$t('references.required-field') + $t('profile.profile.district-label')"
                                        name="city_id">
                            <el-select
                                :placeholder="$t('profile.profile.district-placeholder')"
                                v-model="account.city_id"
                                :loading="citiesLoading"
                                @change="getLocalities"
                                filterable>
                                <el-option
                                    v-for="item in cities"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>
                            <form-group :field="$v.account.locality_id"
                                        :label="$t('references.required-field') + $t('profile.profile.city-label')"
                                        name="locality_id"
                                        >
                            <el-select
                                autocomplete="nope"
                                v-model="account.locality_id"
                                :placeholder="$t('profile.profile.city-placeholder')"
                                :loading="schoolsLoading"
                                @change="getSchools"

                                filterable>
                                <el-option
                                    v-for="item in localities"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>


                            <form-group :field="$v.account.school_id" :label="$t('references.required-field') + $t('profile.profile.organization-name-label')">
                            <el-select
                                v-model="account.school_id"
                                :placeholder="$t('profile.profile.organization-name-placeholder')"
                                filterable>
                                <el-option
                                    :no-match-text="$t('references.schools-not-found')"
                                    :no-data-text="$t('references.schools-not-found')"
                                    v-for="item in schools"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            </form-group>

                            <form-group :field="$v.account.language_id"
                                        :label="$t('references.required-field') + $t('profile.profile.education-language-label')"
                                        name="language_id">
                            <el-select
                                autocomplete="nope"
                                v-model="account.language_id"
                                :placeholder="$t('profile.profile.education-language-label')"
                                >
                                <el-option
                                    :key="1"
                                    :label="$t('profile.profile.language-kz')"
                                    :value="1">
                                </el-option>
                                <el-option
                                    :key="2"
                                    :label="$t('profile.profile.language-ru')"
                                    :value="2">
                                </el-option>
                                <el-option
                                    :key="3"
                                    :label="$t('profile.profile.language-en')"
                                    :value="3">
                                </el-option>
                            </el-select>
                            </form-group>


                            <form-group :field="$v.account.change_of_training_id"
                                        :label="$t('references.required-field') + $t('profile.profile.change_of_training-label')"
                                        name="change_of_training_id">
                            <el-select
                                autocomplete="nope"
                                v-model="account.change_of_training_id"
                                :placeholder="$t('profile.profile.change_of_training-placeholder')">
                                <el-option
                                    :key="1"
                                    :label="1+' '+$t('profile.profile.change_of_training')"
                                    :value="1">
                                </el-option>
                                <el-option
                                    :key="2"
                                    :label="2+' '+$t('profile.profile.change_of_training')"
                                    :value="2">
                                </el-option>
                                <el-option
                                    :key="3"
                                    :label="3+' '+$t('profile.profile.change_of_training')"
                                    :value="3">
                                </el-option>
                            </el-select>
                            </form-group>

                        </div>
                        <div class="col-lg-4 col-md-12">
                            <form-group :field="$v.account.email" :label="$t('references.required-field') + $t('profile.profile.email-label')" name="email">
                            <!--                      <label :class="{'text-danger': !$v.account.email.email}" class="signin-label" for="email">{{ $t('references.email-label') }}*</label>-->
                            <el-input
                                id="email"
                                :placeholder="$t('profile.profile.email-placeholder')"
                                v-model="account.email"
                                @change="$v.account.email.$touch()"
                                clearable>
                            </el-input>
                            </form-group>

                            <form-group :field="$v.account.class_id"
                                        :label="$t('references.required-field') + $t('profile.profile.class-label')"
                                        name="class_id">
                            <el-select
                                autocomplete="nope"
                                v-model="account.class_id"
                                :placeholder="$t('profile.profile.class-placeholder')"
                                >
                                <el-option
                                    :key="5"
                                    :label="5+$t('profile.profile.class')"
                                    :value="5">
                                </el-option>
                                <el-option
                                    :key="6"
                                    :label="6+$t('profile.profile.class')"
                                    :value="6">
                                </el-option>
                            </el-select>
                            </form-group>

                            <form-group :field="$v.account.password"
                                        :label="$t('references.required-field') + $t('profile.profile.password-label')"
                                        name="password">
                            <el-input
                                :placeholder="$t('profile.profile.password-placeholder')"
                                v-model="password"
                                @input="onPasswordInput"
                                @change="$v.password.$touch()"
                                show-password>
                            </el-input>
                            </form-group>
                            <form-group :field="$v.account.confirm_password"
                                        :label="$t('references.required-field') + $t('profile.profile.password-label-submit')"
                                        name="password">
                            <el-input
                                :placeholder="$t('profile.profile.password-placeholder-submit')"
                                v-model="confirm_password"
                                @input="onPasswordConfirmInput"
                                @change="$v.confirm_password.$touch()"
                                show-password></el-input>
                            </form-group>
                            <form-group :field="$v.account.phone"
                                        :label="$t('references.required-field') + $t('profile.profile.phone-label')"
                                        name="phone">
                            <el-input
                                :disabled="phoneVerified"
                                :placeholder="$t('profile.profile.phone-placeholder')"
                                v-model="phone"
                                v-mask="'+7 (###) ### ##-##'"
                                @input="enforcePhoneFormat"
                                clearable>
                            </el-input>
                            </form-group>
                            <div class="signup-additional-info pb-2">
                            {{ $t('signup.registration-is-possible') }}
                            </div>
                            <div class="phone-is-confirmed pb-2 d-none">
                                <img width="15" src="/images/tick-green.svg" class="mr-1">
                                {{ $t('signup.phone-is-confirmed') }}
                            </div>
                            <div class="confirm-phone-btn-block">
                                <button class="confirm-phone-btn" @click="openModal" v-b-modal.sms-modal type="button">
                                    {{ $t('signup.confirm-phone') }}
                                </button>
                            </div>
                            <!-- <div class="signup-accept-block">
                                <div class="form-check pl-0 pt-4">
                                    <label class="form-check-label" for="all">
                                        {{ $t("signup.accept") }} 
                                        <span class="form-check-label-accept">{{ $t("signup.accept-2") }}</span>
                                        <input class="form-check-input" type="checkbox" name="filter" id="all" value="0"
                                                v-model="acceptData">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-12 pt-2 px-0">
                            <div class="signup-accept-block signup-accept-block--nonresident">
                                <div class="form-check pl-0">
                                    <label class="form-check-label" for="all">
                                        {{ $t("signup.accept") }} 
                                        <span class="form-check-label-accept">{{ $t("signup.accept-2") }}</span>
                                        <input class="form-check-input" type="checkbox" name="filter" id="all" value="0"
                                                v-model="acceptData">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="signup-submit-block">
                                <div class="signup__fill-fields">
                                    {{ $t('signup.fill-fields') }}
                                </div>
                                <el-button @click="createUser" class="btn-finish ml-0" :disabled="!acceptData">
                                    {{ $t('signup.signup-btn') }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.error-modal') }}</h3>
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </b-modal> -->

    <b-modal id="sms-modal" hide-footer hide-header centered class="sms-modal">
      <button class="btn-close-top" @click="$bvModal.hide('sms-modal')">
        <img width="12" src="/images/close.svg" alt="">
      </button>
      <h3 class="sms-modal-title">{{ $t('signup.enter-sms-code') }}</h3>
      <!-- <span>{{ $t('signup.sended-to') }} {{phone}}</span> -->
      <div class="sms-modal-text">{{ $t('signup.sended-to') }} +7 (XXX) XXX XX-XX</div>
      <div id="form" class="sms-modal-form">
        <CodeInput :field-width="44" :field-height="56" :loading="sendingSms" :fields="4" class="input"
                   v-on:change="onChange" v-on:complete="onComplete"/>
        <p class="sms-modal-incorrect" v-if="invalidCode">{{ $t('signup.incorrect-code') }}</p>
      </div>
      <!-- <p v-if="canResend"><a @click="resendSms">{{ $t('signup.resend-code') }}</a></p> -->
      <div v-if="!canResend" class="can-resend-sms">{{ $t('signup.resend-code-after') }}{{ resendSec }}
        {{ $t('signup.resend-code-seconds') }}
      </div>
    </b-modal>

  </form>
</template>
<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email, helpers} from "vuelidate/lib/validators";
import CodeInput from "vue-verification-code-input";

const passwordHelper = helpers.regex('passwordHelper', /^\d+$/);

export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      isNotResident: false,
      isResident: true,
      iinCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      regions: [],
      cities: [],
      schools: [],
      phone: '',
      parentPhone: '',
      sendingSms: false,
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      schoolsLoading: false,
      invalidCode: false,
      canResend: false,
      phoneVerified: false,
      parentPhoneVerified: false,
      resendSec: 60,
      acceptData: false,
      rules: [
        {active: false, message: this.$t('reset-password.minimum-symbols'), regex: /.{8,}/},
        {active: false, message: this.$t('reset-password.number-required'), regex: /[0-9]+/},
        {active: false, message: this.$t('reset-password.lowercase-required'), regex: /[a-z]+/},
        {active: false, message: this.$t('reset-password.uppercase-required'), regex: /[A-Z]+/},
        // { active: false, message:"Допустимые символы: ! . - _ ?",  regex:/[!.\-_?]/ },
      ],
      submitted: false,
      passwordValid: false,
      password: '',
      confirm_password: '',
      genders: [
        {
          id: "1",
          name: this.$t('references.gender-male')
        },
        {
          id: "2",
          name: this.$t('references.gender-female')
        }
      ],
      role: '1',
      account: {
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        locality_id: '',
        region_id: '',
        city_id: '',
        document_id:'',
        language_id:'',
        class_id:'',
        school_id:'',
        change_of_training_id: '',
        phone: '',
        email: '',
        isResident:'true',
        sex: '1',
        gender: '',
        password: '',
        confirm_password: '',
      },
    }
  },
  validations: {
    phone: {
      required: false,
      minLength: minLength(18)
    },
    password: {
      // required,
      minLength: minLength(8),
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        return containsUppercase && containsLowercase && containsNumber
      }
    },
    confirm_password: {
      // required,
      minLength: minLength(8),
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        return containsUppercase && containsLowercase && containsNumber
      }
    },
    parentPhone: {
      required: false,
      minLength: minLength(18)
    },
    account: {
      iin: {required, minLength: minLength(12)},
      phone: {required, minLength: minLength(11)},

      last_name: {required},
      first_name: {required},
      birth_date: {required},
      region_id: {required},
      city_id: {required},
      locality_id: {required},
      language_id:{required},
      class_id:{required},
      school_id:{required},
      email: {required},
      change_of_training_id: {required},
      password: {
        required, passwordHelper, minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          return containsUppercase && containsLowercase && containsNumber
        }
      },
      confirm_password: {required, passwordHelper},
    },
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return (this.password !== this.confirm_password)
      } else {
        return false
      }
    },
    passwordsFilled() {
      return (this.password !== '' && this.confirm_password !== '')
    },
    passwordValidation() {
      let errors = []
      for (let condition of this.rules) {
        condition.active = condition.regex.test(this.password);
        // if (!condition.regex.test(this.password)) {
        errors.push({
          message: condition.message,
          active: condition.active
        });
        // }
      }
      let error = false;
      Object.entries(errors).forEach(entry => {
        const [key, value] = entry;
        if (value.active == false) {
          error = true;
        }
      });
      this.passwordValid = !error;
      return {valid: !error, errors}
    }
  },
  mounted() {
    if (this.$store.state.password.savedPassword != 'undefined') {
      this.password = this.$store.state.password.savedPassword;
      this.confirm_password = this.$store.state.password.savedPassword;
    }
    this.regionsLoading = true;
    this.$http.get(window.API_ROOT + '/api/references/regions')
        .then((res) => {
          if (res.body.data) {
            this.regionsLoading = false;
            this.regions = res.body.data;
          }
        }).catch((e) => {
      this.regionsLoading = false
    })
    if (sessionStorage.getItem('phone-verified')) {
      this.phoneVerified = (sessionStorage.getItem('phone-verified') == 'true');
    }
    if (sessionStorage.getItem('parent-phone-verified')) {
      this.parentPhoneVerified = (sessionStorage.getItem('parent-phone-verified') == 'true');
    }
    if (sessionStorage.getItem('account')) {
      this.account = JSON.parse(sessionStorage.getItem('account'));
      this.phone = this.account.phone;
      this.enforcePhoneFormat();
    }
    if (sessionStorage.getItem('parent')) {
      this.parent = JSON.parse(sessionStorage.getItem('parent'));
      this.enforceParentPhoneFormat();
    }
    if (this.account.region_id !== '') {
      this.$http.get(window.API_ROOT + '/api/references/districts/' + this.account.region_id)
          .then((res) => {
            if (res.body.data) {
              this.cities = res.body.data;
            }
          }).catch((e) => {
      })
    }

    if (this.account.city_id !== '') {
      this.$http.get(window.API_ROOT + '/api/references/localities/' + this.account.city_id)
          .then((res) => {
            if (res.body.data) {
              this.localities = res.body.data;
            }
          }).catch((e) => {
      })
    }

  },
  methods: {
    getDataFromIin(val) {
      if (val.length == 12) {
        console.log("asdasd " + val.length + " " + val);


        let date = new Date();

        this.iinCorrect = true;
        let year;
        // let monthVal;
        if (parseInt('20' + val.slice(0, 2), 10) > date.getFullYear()) {
          year = parseInt('19' + val.slice(0, 2), 10);
        } else {
          year = parseInt('20' + val.slice(0, 2), 10);
        }

        let month = val.slice(2, 4)
        let day = val.slice(4, 6)

        if (this.role == '1') {
          this.iinCorrect = true;
          this.account.birth_date = `${year.toString()}-${month}-${day}`;
          if (this.account.iin[6] == 3 || this.account.iin[6] == 5) {
            this.account.gender = this.$t('references.gender-male');
          } else if (this.account.iin[6] == 4 || this.account.iin[6] == 6) {
            this.account.gender = this.$t('references.gender-female');
          } else {
            this.account.gender = '';
          }
        } else {
          this.iinCorrect = true;
          this.parent.birth_date = `${year.toString()}-${month}-${day}`;
          if (this.parent.iin[6] == 3 || this.parent.iin[6] == 5) {
            this.parent.gender = this.$t('references.gender-male');
          } else if (this.parent.iin[6] == 4 || this.parent.iin[6] == 6) {
            this.parent.gender = this.$t('references.gender-female');
          } else {
            this.parent.gender = '';
          }
        }


      }
    },
    getCities(id) {
      this.$v.account.region_id.$touch();
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              this.account.city_id = '';
            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalities(id) {
      this.$v.account.city_id.$touch();
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              this.account.locality_id = '';
            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    getSchools(id){
      this.$v.account.locality_id.$touch();
      this.schoolsLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
          .then((res) => {
            this.schoolsLoading = false;
            if (res.body.data) {
              if (res.body.data){
                if (res.body.data.length){
                  this.notFound = false;
                } else {
                  this.notFound = true;
                  this.$bvModal.show('school-modal');
                }
              }
              this.schools = res.body.data;
              this.school.school_id = '';
            }
          }).catch((e) => {
        this.schoolsLoading = false;
      })
    },
    openModal() {
      if (!this.iinCorrect) {
        this.$bvModal.show('error-modal');
        return;
      }

      switch (this.role) {
        case "1":
          this.$v.account.$touch();
          if (this.$v.account.$invalid) return
          if (!this.phoneVerified) {
            if (this.resendSec == 60) {
              setInterval(() => {
                this.resendSec--;
                if (this.resendSec == 0) {
                  this.canResend = true;
                }
              }, 1000);
            }
            this.sendSms({
              phone: this.account.phone,
              locale: this.$i18n.locale
            });
            this.$bvModal.show('sms-modal');
          }
          break;
        case "2":
          this.$v.parent.$touch();
          if (this.$v.parent.$invalid) return
          if (!this.parentPhoneVerified) {
            if (this.resendSec == 60) {
              setInterval(() => {
                this.resendSec--;
                if (this.resendSec == 0) {
                  this.canResend = true;
                }
              }, 1000);
            }
            this.sendSms({
              phone: this.parent.phone,
              locale: this.$i18n.locale
            });
            this.$bvModal.show('sms-modal');
          }
          break;
      }
    },
    resendSms() {
      this.canResend = false;
      this.resendSec = 60;
      this.sendSms({
        phone: this.account.phone,
        locale: this.$i18n.locale
      });
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.phone = x[0];
    },
    enforceParentPhoneFormat() {
      this.$v.phone.$touch();
      let x = this.parentPhone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.parent.phone = x[0];
    },
    onChange(v) {
      // console.log('on change');
    },
    onComplete(v) {
      this.invalidCode = false;
      this.sendingSms = true;
      this.$http.post(window.API_ROOT + '/api/sms-verification', {
        phone: (this.role == '1') ? this.account.phone : this.parent.phone,
        code: v,
      }).then((res) => {
        if (res.body.status == 'success') {
          if (this.role == '1') {
            sessionStorage.setItem('account', JSON.stringify(this.account));
            sessionStorage.setItem('phone-verified', true);
            this.phoneVerified = true;
          } else {
            sessionStorage.setItem('parent', JSON.stringify(this.parent));
            sessionStorage.setItem('parent-phone-verified', true);
            this.parentPhoneVerified = true;
          }
          this.$bvModal.hide('sms-modal');
        }
        if (res.body.status == 'error') {
          this.invalidCode = true;
        }
        this.sendingSms = false;
      }).catch(() => {
        this.sendingSms = false;
      })
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    onSubmit(data) {
      this.login(data)
          .then(() => {
            this.$router.push(this.$route.query.redirect || '/')
          })
          .catch(() => {
          })
    },
    onPasswordInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
      this.account.password = this.password;
    },
    onPasswordConfirmInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.confirm_password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
      this.account.confirm_password = this.confirm_password;
    },
    savePassword() {
      this.$v.$touch()
      console.log(this.$v);
      if (this.$v.$invalid || !this.passwordValid) return
      if (this.password === this.confirm_password) {
        this.$store.commit('SAVE_PASSWORD', this.password);
        this.$router.push({path: '/signup/education'})
      } else {
        Vue.toastr({
          message: 'Error',
          description: this.$t('signup.passwords-dont-match'),
          type: 'error'
        })
      }
    },
    createUser() {
      this.loading = true;
      let form = {};
      let phoneVerified = false;
      if (this.role == '1') {
        form = this.account;
        phoneVerified = this.phoneVerified;
      } else {
        form = this.parent;
        phoneVerified = this.parentPhoneVerified;
      }
      form.role = this.role;
      if (phoneVerified) {
        console.log(form);
        this.$http.post(window.API_ROOT + '/api/user', form)
            .then((res) => {
              this.loading = false;
              if (res.status == 201) {
                sessionStorage.removeItem('account');
                sessionStorage.removeItem('parent');
                sessionStorage.removeItem('education');
                sessionStorage.removeItem('avatar');
                sessionStorage.removeItem('avatar-base64');
                sessionStorage.removeItem('phone-verified');
                this.$store.commit('FORGET_PASSWORD', this.password);

                // this.$bvModal.show('finish-modal');

                Vue.toastr({
                  message: 'Success',
                  description: 'Пользователь успешно создан',
                  type: 'success'
                })

                this.$router.push({path: '/signin'})
              }
            })
            .catch((e) => {
              this.loading = false;
              if (e.status == 400 && e.body.errors) {
                e.body.errors.forEach(function (val) {
                  Vue.toastr({
                    message: 'Error',
                    description: val,
                    type: 'error'
                  })
                })
              }
            })
      } else {
        this.errorMessage = 'Пожалуйста подтвердите номер телефона';
        this.$bvModal.show('error-modal');
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}
</script>


<style lang="less" scoped>
.form-group.has-error > .help-block {
    display: none;
}
.signup-form {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 72px 53px 53px 53px;
    margin-bottom: 32px;
    z-index: 2;
    background-color: #fff;
}
.signup-page-title {
    margin-bottom: 35px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.el-select {
  margin-bottom: 0;
}
.checkbox-block {
  display: flex;
  flex-direction: row;
}
.form-check-label {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.signup-accept-block .checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 4px;
}
.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}
.form-check-label input:checked ~ .checkmark {
  background-color: #473F95;
}
.form-check-label input:checked .form-check-label {
  background-color: #473F95;
}
.checkmark:after {
  content: "✔";
  position: absolute;
  display: none;
  left: 3px;
  font-size: 9px;
}
.form-check-label input:checked ~ .checkmark:after {
  display: block;
}
.form-check-label .checkmark:after {
color: #fff;

}
.el-button.btn-finish.is-disabled {
  background: #CACACA;
  border: 1px solid #CACACA;
  color: #FFFFFF;
}
.btn-finish {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: #5E57A2;
  border-radius: 12px;
  padding: 18px 18px;
  color: #FFFFFF;
  margin: 0;
  min-width: 276px;
}
.btn-finish:hover, .btn-finish:focus {
  color: #FFFFFF;
  background: #5E57A2;
}
.signup-requered-top-mob {
  display: none;
}
.signup-accept-block {
  display: flex;
  flex-direction: column;
}
.signup-accept-block--nonresident {
    max-width: 250px;
    margin: 0 auto;
}
.signup-submit-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 34px;
    align-items: center;
}
.gender-mob {
  display: none;
}
/deep/ .signup-form .el-radio {
    display: flex;
    align-items: center;
}
/deep/ .signup-form .el-radio__label {
    color: #2C2C2C;
}
/deep/ .signup-form .el-radio__input.is-checked .el-radio__inner {
    border-color: #5E57A2;
    background: #5E57A2;
}
/deep/ .signup-form .el-radio__inner:hover {
    border-color: #5E57A2;
}
/deep/ .signup-form .el-radio__inner {
    width: 16px;
    height: 16px;
}
/deep/ .signup-form .el-radio__inner::after {
    width: 8px;
    height: 8px;
}
.signup-form  .checkbox-block {
    padding-bottom: 20px;
}
.signup__required-fields {
    color: #D23168;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.signup__required-fields--resident {
    padding-top: 42px;
    padding-bottom: 24px;
}
.signup__required-fields--non-resident {
    padding: 38px 0;
}
/deep/ .signup-form .form-group > label {
    color: #2C2C2C;
    font-size: 13px;
    line-height: 16px;
    font-family: Inter;
}
/deep/ .signup-date-picker .el-date-table td.current:not(.disabled) span {
        background-color: #5E57A2!important;
}
/deep/ .signup-date-picker .el-date-table td.today span {
    color: #5E57A2;
}
.signup__logo {
    margin-top: -65px;
    margin-bottom: 41px;
    text-align: center;
}
.signup__logo--non {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 48px;
    margin-top: -145px;
    margin-left: -65px;
    max-width: 242px;
    max-height: 219px;
}
.signup-additional-info {
    color: #A9A9A9;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}
.confirm-phone-btn {
    background: #D23168;
    border-radius: 10px;
    border: none;
    letter-spacing: 0.1px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    padding: 12px;
}
.confirm-phone-btn--confirmed {
    background: #CACACA;
    color: #fff;
}
.form-check-label {
    color: #303030;
    font-size: 13px;
    line-height: 15px;
}
.form-check-label-accept {
    color: #D23168;
}
.phone-is-confirmed {
    color: #399300;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
}
.signup__fill-fields {
    color: #E24C4B;
    font-size: 12px;
    line-height: 14px;
    margin: 0 auto 10px auto;
}
.signup-form-bg {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    bottom: 301px;
    max-height: 800px;
}
.btn-close-top {
    border: none;
    background-color: #473F95;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    position: absolute;
    right: -16px;
    top: -16px;
}
.sms-modal-title {
    color: #473F95;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
}
.sms-modal-text {
    color: #303030;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 22px;
    text-align: center;
}
.can-resend-sms {
    color: #D23168;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 38px;
}
.sms-modal-incorrect {
    color: #D23168;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 4px;
}
/deep/ #sms-modal .modal-body {
    background-image: url("/images/vave-bg-modal.svg");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}
/deep/ .input.react-code-input-container {
    margin: 0 auto;
    width: 254px!important;
}
/deep/ .react-code-input>input {
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 4px;
    background: #FFFFFF;
    border: none;
    color: #473F95;
    font-size: 40px;
    line-height: 24px;
    margin-right: 26px;
}
/deep/ .react-code-input>input:last-child {
    margin-right: 0;
    border-right: none;
}
/deep/ .react-code-input>input:focus {
    border: 1px solid #473F95!important;
}
.signup__attach-block {
    display: flex;
    align-items: center;
    border: 1px dashed #473F95;
    width: fit-content;
    padding: 4px 12px 4px 4px;
    border-radius: 4px;
}
.signup__attach-btn {
    border: 1px solid #DDDBDA;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    color: #473F95;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-right: 21px;
    padding: 5px 22px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.signup__image-upload input {
    display: none;
}
.signup__attach-btn img {
    margin-right: 8px;
}
.signup__attached-file {
    color: #737373;
    font-size: 13px;
    line-height: 20px;
}
@media (max-width: 991px) {
  .signup-form {
    box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
    border-radius: 8px;
    padding: 25px 21px;
  }

  .signup-requered-top {
    display: none;
  }

  .signup-requered-top-mob {
    display: block;
  }

  .signup-accept-block {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }

  .signup-requered-fields {
    margin-top: 16px;
  }

  .signup-submit-block {
    justify-content: center;
  }

  .gender-mob {
    display: none;
  }

  .gender-mob .el-radio-group {
    text-align: center;
  }

  .gender-mob .el-radio-button__inner {
    padding: 10px 31px;

  }

  .gender-mob .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #18A0FB;
  }

  /* .mob-hidden {
    display: none;
  } */
}

@media (max-width: 768px) {
  .btn-block {
    text-align: center;
  }
}

</style>



